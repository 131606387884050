import React, { useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { Link } from "react-router-dom"

export default function AddAdmin() {
    const [error] = useState("")
    const [status, setStatus] = useState("Submit");
    let post_base_url = "https://firestore.googleapis.com/v1/projects/esp32-smartmailbox/databases/(default)/documents/"
    const handleAddAdminSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");
        const { n_f_name, n_l_name, n_email, n_address } = e.target.elements;
        let n_details = {
            n_f_name: n_f_name.value,
            n_l_name: n_l_name.value,
            n_email: n_email.value,
            n_address: n_address.value
        };
        let addToIndex = '{"fields":{"address":{"stringValue":"' + n_details.n_address + '"},"email":{"stringValue":"' + n_details.n_email + '"}}}'
        let indexResponse = await fetch("https://firestore.googleapis.com/v1/projects/esp32-smartmailbox/databases/(default)/documents/index?documentId=" + n_details.n_f_name + '.' + n_details.n_l_name, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: addToIndex
        });
        let postdata = '{"fields":{"f_name":{"stringValue":"' + n_details.n_f_name + '"},"l_name":{"stringValue":"' + n_details.n_l_name + '"},"email":{"stringValue":"' + n_details.n_email + '"},"admin":{"booleanValue":"true"}}}'
        let response = await fetch(post_base_url + n_details.n_address + "?documentId=" + n_details.n_f_name + '.' + n_details.n_l_name, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: postdata
        });
        setStatus("Submit");
        if (response.status === 200 && indexResponse.status === 200)
            alert("Successfully updated");
        else
            alert("Submition status " + response.status + " adding to index status " + indexResponse.status);
    }

    return (
        <>
      <Card style={{backgroundColor: "transparent", border: "0"}}>
                <Card.Body>
                    <h2 className="text-center mb-4" style={{color: "gray"}}>Add new Admin</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <div className="w-100 mt-2 ">
                        <Form id="myform" onSubmit={handleAddAdminSubmit}>
                            <Form.Group id="fname">
                                <Form.Label>First name *</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    id="n_f_name"
                                    placeholder="First name (required!)"
                                />
                            </Form.Group>
                            <Form.Group id="lname">
                                <Form.Label>Last Name *</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    id="n_l_name"
                                    placeholder="Last name (required!)"
                                />
                            </Form.Group>
                            <Form.Group id="n_email">
                                <Form.Label>Email *</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    id="n_email"
                                    placeholder="Email for admin login (required!)"
                                />
                            </Form.Group>
                            <Form.Group id="n_address">
                                <Form.Control
                                    type="hidden"
                                    id="n_address"
                                    defaultValue="admins"
                                />
                            </Form.Group>
                            <Button className="w-100" type="submit">
                                {status}
                            </Button>
                        </Form>
                    </div>
                    <div className="w-100 text-center mt-2">
                        <Link to="/">Cancel</Link>
                    </div>
                </Card.Body>
                
            </Card>
            
        </>
    )
}