import React, { useState } from "react"
import { Card, Button, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
const fetch = require('sync-fetch');

// eslint-disable-next-line
var mytestobj = new Object();
var card_paragraphs11 = [];

export function user_infos(currentUser) {
  for (var mytestobjKey in mytestobj) {
    if (mytestobj.hasOwnProperty(mytestobjKey)) {
      delete mytestobj[mytestobjKey];
    }
  }

  let post_base_url = "https://firestore.googleapis.com/v1/"
  var url = 'https://firestore.googleapis.com/v1/projects/esp32-smartmailbox/databases/(default)/documents/';
  var index_url = 'https://firestore.googleapis.com/v1/projects/esp32-smartmailbox/databases/(default)/documents/index';
  var get_address = fetch(index_url).json()
  var addr = undefined;
  for (let j = 0; j < get_address.documents.length; j++) {
    if (get_address.documents[j].fields.email !== undefined) {
      if (get_address.documents[j].fields.email.stringValue.toString().trim().toLowerCase() === currentUser.email.toString().trim().toLowerCase()) {
        addr = j;
      }
    }
  }
  if (addr !== undefined) {
    if (get_address.documents[addr].fields.address !== undefined) { var u_a = get_address.documents[addr].fields.address.stringValue }
    var testob = fetch(url + u_a).json()
    var doc = undefined;
    for (let i = 0; i < testob.documents.length; i++) {
      if (testob.documents[i].fields.email !== undefined) {
        if (testob.documents[i].fields.email.stringValue.toString().trim().toLowerCase() === currentUser.email.toString().trim().toLowerCase()) {
          doc = i;
        }
      }
    }
    if (doc !== undefined) {
      var cnn_o = 'card_num';
      for (let card_ind = 0; card_ind < 12; card_ind++) {
        if (testob.documents[doc].fields[cnn_o] !== undefined && testob.documents[doc].fields[cnn_o].integerValue !== '0') {
          mytestobj[cnn_o] = testob.documents[doc].fields[cnn_o].integerValue;
        }
        cnn_o = 'card_num_' + card_ind;
      }
      if (testob.documents[doc].fields.box_num !== undefined) { var box_num = testob.documents[doc].fields.box_num.integerValue }
      if (testob.documents[doc].fields.f_name !== undefined) { var fn = testob.documents[doc].fields.f_name.stringValue }
      if (testob.documents[doc].fields.l_name !== undefined) { var ln = testob.documents[doc].fields.l_name.stringValue }
      if (testob.documents[doc].fields.email !== undefined) { var user_email = testob.documents[doc].fields.email.stringValue.toLowerCase() }
      if (testob.documents[doc].fields.admin !== undefined) { var is_admin = testob.documents[doc].fields.admin.booleanValue }
      if (testob.documents[doc].name !== undefined) { var user_adress = testob.documents[doc].name }
      if (testob.documents[doc].fields.last_access !== undefined) { var last_access = testob.documents[doc].fields.last_access.stringValue }
      if (u_a !== "admins") {
        var last_mail = fetch(url + u_a + "/slot_access").json()
        var last_mail_time = "Undefined"
        if(last_mail.error === undefined){
          if (last_mail.fields.last_mail_time !== undefined) { last_mail_time = last_mail.fields.last_mail_time.stringValue }
        }
      }
    }

    mytestobj.doc = doc
    mytestobj.user_adress = user_adress
    mytestobj.post_base_url = post_base_url
    mytestobj.ln = ln
    mytestobj.fn = fn
    mytestobj.box_num = box_num
    mytestobj.last_access = last_access
    mytestobj.last_mail_time = last_mail_time
    mytestobj.user_email = user_email
    mytestobj.is_admin = is_admin

    return mytestobj
  }
}

export default function Dashboard() {
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory()
  user_infos(currentUser)
  // const mqtt = require('mqtt')
 
  
  let cnn_o = 'card_num';
  
  
  card_paragraphs11.length = 0;



  for (let index_1 = 0; index_1 < 11; index_1++) {
    if ([cnn_o] in mytestobj) {
      card_paragraphs11.push(
        <p><img src={"nfc.png"} alt={"card"} style={{ width: "20px" }} ></img> {mytestobj[cnn_o]}</p>);
    }
    cnn_o = 'card_num_' + index_1;
  }

  async function handleLogout() {
    mytestobj.doc = undefined;
    setError("")
    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }

  /* async function handleDoor() {
    const options = {
      //protocol: 'wss',

      // Clean session
      clean: true,
      //connectTimeout: 4000,
      // Auth
      clientId: 'webbutton',
      useSSL: true ,
      qos: 0, 
      //rejectUnauthorized : false,
      username: 'ibrahim',
      password: 'Aa789789'
    }
    const client  = mqtt.connect('wss://5247d351f17c44b981643861ca6beade.s2.eu.hivemq.cloud:8884/mqtt', options)
    client.on('connect', function (connack) {
      console.log('Connected')
      client.publish('testTopic', 'Triggered From web')
    })
       client.publish('testTopic', '18', { qos: 0, retain: false }, function (error) {
      if (error) {
        console.log(error)
      } else {
        console.log('Published')
      }
    })
    alert("Successfully connected.. Click OK/cancel to open");
    //client.end();
    // client.publish('testTopic', 'Hello from web')
    // client.on('connect', function () {
    //   console.log('Connected')
    //   client.publish('testTopic', 'Hello from web')
    //   console.log('published')
    //   client.subscribe('testTopic', function (err) {
    //     if (!err) {
    //       client.publish('testTopic', 'Hello from web')
    //       console.log('published')
    //     }
    //   })
    // })
    // client.publish('testTopic', '18', { qos: 0, retain: false }, function (error) {
    //   if (error) {
    //     console.log(error)
    //   } else {
    //     console.log('Published')
    //   }
    // })
    // 
  }
 */

  var userdetails_admin = [];
  var userdetails_box = [];
  var userdetails_last_access = [];
  var userdetails_last_mail_time = [];
  var userdetails_cards = [];
  var userdetails_update = [];
  var userdetails_admin_tools = [];
  if (mytestobj.is_admin === true) {
    userdetails_admin.push(<span>
      <img src="icon-admin_1.png" style={{ width: "10%" }}></img>
      <br></br><br></br>
    </span>)
  }
  if (mytestobj.is_admin !== true && mytestobj.box_num !== undefined) {
    userdetails_box.push(<span><br></br> <strong>Box Nr:</strong> <br></br> <i>{mytestobj.box_num} </i></span>)
  }
  if (mytestobj.is_admin !== true && mytestobj.last_access !== undefined) {
    userdetails_last_access.push(<span>
      <br></br><strong>Last accessed on:</strong> <br></br> <i>{mytestobj.last_access} </i>
    </span>)
  }
  if (mytestobj.is_admin !== true && mytestobj.last_mail_time !== undefined) {
    userdetails_last_mail_time.push(<span>
      <br></br><strong>Mail-slot last opened on:</strong> <br></br> <i>{mytestobj.last_mail_time} </i>
    </span>)
  }
  if (!mytestobj.is_admin && card_paragraphs11 !== null && card_paragraphs11[0] !== undefined) {
    userdetails_cards.push(<span>
      <br></br> <strong>Cards:</strong> <br></br> <i>{card_paragraphs11} </i>
    </span>)
  }
  if (mytestobj.is_admin !== true) {
    userdetails_update.push(<Link to="/update-profile" className="btn btn-primary w-100 mt-3">
      Update account
    </Link>)
  }
  else {
    userdetails_admin_tools.push(<span><Link to="/admin_tools" className="btn btn-primary w-100 mt-3">
      Add new user
    </Link>
      <Link to="/find-user" className="btn btn-primary w-100 mt-3">
        Manage users
      </Link>
      <Link to="/add-admin" className="btn btn-primary w-100 mt-3">
        Add new adminstrator
      </Link>
      <Link to="/update-admin" className="btn btn-primary w-100 mt-3">
        Update current Admin
      </Link>
      <Link to="/IoTDashboard" className="btn btn-primary w-100 mt-3">
        {mytestobj.fn}'s Smart-Home 
      </Link>       
      {/* <div className="w-100 text-center mt-2" >
        <Button variant="link" onClick={handleDoor}>
          <img src={"door1.png"} alt={"door"} style={{ width: "40px" }} ></img>
        </Button>
      </div> */}
      </span>)
  }

  return (
    <>
      <div style={{textAlign: "center"}}>{userdetails_admin}</div>
      {/* <p className="text-center"><img src="icon.gif" alt="Smart Mailboxes" style={{ paddingTop: "0", width: "266px", height: "85px", opacity: "90%" }}></img></p> */}
      <p className="text-center"><i className="text-hello">Hello {mytestobj.fn} {mytestobj.ln}!</i></p>

      {error && <Alert variant="danger">{error}</Alert>}

      <Card style={{backgroundColor: "transparent", border: "0", color: "white", textAlign: "center"}}>
        <Card.Body>
          <strong>E-mail:</strong> <br></br> <i>{currentUser.email} </i>
          <br></br>
          <strong>First name:</strong> <br></br> <i>{mytestobj.fn} </i>
          <br></br>
          <strong>Last Name:</strong> <br></br> <i>{mytestobj.ln} </i>
          {userdetails_box}
          {userdetails_last_access}
          {userdetails_last_mail_time}
          {userdetails_cards}
        </Card.Body>
      </Card>
      <Card style={{backgroundColor: "transparent", border: "0"}}>
        <Card.Body>
          {userdetails_update}
          {userdetails_admin_tools}
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2" >
        <Button variant="link" onClick={handleLogout}>
          <img src={"logout.png"} alt={"logout"} style={{ width: "40px" }} ></img>
        </Button>
      </div>

    </>
  )
}
export const user_infos1 = mytestobj
