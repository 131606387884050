import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import { user_infos } from "./Dashboard"

export default function UpdateAdminProfile() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { currentUser, updatePassword, updateEmail } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState("Submit");
  const history = useHistory()

  function handleAdminSubmit(e) {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    const promises = []
    setLoading(true)
    setError("")

    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value))
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value))
    }

    Promise.all(promises)
      .then(() => {
        history.push("/")
      })
      .catch(() => {
        setError("Failed to update account")
      })
      .finally(() => {
        setLoading(false)
      })
  }
  var testobjj = user_infos(currentUser)
  
  const handleNameSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const{n_f_name, n_l_name} = e.target.elements;
    let n_details = {
      n_f_name: n_f_name.value,
      n_l_name: n_l_name.value,
    };

    let postdata = '{"fields":{"f_name":{"stringValue":"'+n_details.n_f_name+'"},"l_name":{"stringValue":"'+n_details.n_l_name+'"},"email":{"stringValue":"'+currentUser.email+'"},"admin":{"booleanValue":"true"}}}'
    let response = await fetch(testobjj.post_base_url + testobjj.user_adress , {
       headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'PATCH',                                                              
      body: postdata                                       
    });
    
    setStatus("Submit");
    if(response.status === 200)
    alert("Successfully updated");
    else 
    alert("Submition status "+ response.status);
    }
  
  return (
    <>
      <Card style={{backgroundColor: "transparent", border: "0"}}>
        <Card.Body>
        <h2 className="text-center mb-4" style={{color: "gray"}}>Admin Credentials</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleAdminSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                ref={emailRef}
                required
                defaultValue={currentUser.email}
              />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                ref={passwordRef}
                placeholder="Leave blank to keep the same"
              />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control
                type="password"
                ref={passwordConfirmRef}
                placeholder="Leave blank to keep the same"
              />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Update
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <Card style={{backgroundColor: "transparent", border: "0"}}>
        <Card.Body>
        <h2 className="text-center mb-4" style={{color: "gray"}}>Admin Profile Information</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <div className="w-100 mt-2 ">
          <Form id="myform" onSubmit={handleNameSubmit}>
            <Form.Group id="fname">
              <Form.Label>First name</Form.Label>
              <Form.Control
                type="text"
                id="n_f_name"
                defaultValue={testobjj.fn}
              />
            </Form.Group>
            <Form.Group id="lname">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                id="n_l_name"
              defaultValue={testobjj.ln}
              />
            </Form.Group>
            <Button  className="w-100" type="submit">
            {status}
            </Button>
          </Form>
      </div>
      <div className="w-100 text-center mt-2">
        <Link to="/">Cancel</Link>
      </div>
        </Card.Body>
      </Card>
      
    </>
  )
}