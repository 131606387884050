import React from "react"
// import React, { useState, useCallback } from "react"
// import { Alert } from "react-bootstrap"
import { Link } from "react-router-dom"

export default function OpenFrontdoor() {
  // const [error, setError] = useState("")
  // const [variant, setvariant] = useState("")


  // const mqttfunc = useCallback(event => {
    const mqtt = require('mqtt')

    const options = {
      //protocol: 'wss',

      // Clean session
      clean: true,
      //connectTimeout: 4000,
      // Auth
      clientId: 'webbutton',
      useSSL: true ,
      // qos: 0, 
      //rejectUnauthorized : false,
      username: 'ibrahim',
      password: 'Aa789789'
    }
    const client  = mqtt.connect('wss://5247d351f17c44b981643861ca6beade.s2.eu.hivemq.cloud:8884/mqtt', options)
    // client.on('connect', function (connack) {
    //   console.log('Connected')
    //   // client.publish('testTopic', 'Triggered From web')
    // })
    
    client.publish('testTopic', '18', { qos: 0, retain: false }, function (error) {
      if (error) {
        console.log(error)
        // setError("Failed to open the door")
        // setvariant("danger")
      } else {
        console.log('Published')
        // setError("Door is Opened!")
        // setvariant("success")
        // alert("Front door is successfully opened..");
      }
    })
    
  // }, []);
  // window.ononline = mqttfunc();
      

  return (
    <>
      {/* {error && <Alert variant={variant}>{error}</Alert>} */}

      <button className="btn btn-primary w-100 mt-3" onClick={() => window.location.reload(false)}>
      <img src={"reload.png"} alt={"reopen"} style={{ width: "30px", marginRight: "10px"  }} ></img> Reopen!</button>
      <Link to="/" className="btn btn-secondary w-100 mt-3">
        Back to Dashboard
      </Link> 
      <div className="w-100 text-center mt-2">
        <Link to="/">Cancel</Link>
      </div>
    </>
  )
}
