import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import { user_infos } from "./Dashboard"

export default function UpdateProfile() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { currentUser, updatePassword, updateEmail } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState("Submit");
  const history = useHistory()
  var card_1_delete_button = [];
  var card_2_delete_button = [];
  var card_3_delete_button = [];
  var card_4_delete_button = [];

  function handleSubmit(e) {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    const promises = []
    setLoading(true)
    setError("")

    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value))
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value))
    }

    Promise.all(promises)
      .then(() => {
        history.push("/")
        handleAddcardsubmit();
      })
      .catch(() => {
        setError("Failed to update account")
      })
      .finally(() => {
        setLoading(false)
      })
  }
  var testobjj = user_infos(currentUser)
  if (testobjj.card_num !== undefined) {
    card_1_delete_button.push(
      <Form.Label><button className="btn btn-delete w-100 mt-3" onClick={() => deleteCard("n_card_num")}>Delete card 1</button></Form.Label>
    )
  }

  if (testobjj.card_num_1 !== undefined) {
    card_2_delete_button.push(
      <Form.Label><button className="btn btn-delete w-100 mt-3" onClick={() => deleteCard("n_card_num_1")}>Delete card 2</button></Form.Label>
    )
  }

  if (testobjj.card_num_2 !== undefined) {
    card_3_delete_button.push(
      <Form.Label><button className="btn btn-delete w-100 mt-3" onClick={() => deleteCard("n_card_num_2")}>Delete card 3</button></Form.Label>

    )
  }

  if (testobjj.card_num_3 !== undefined) {
    card_4_delete_button.push(
      <Form.Label><button className="btn btn-delete w-100 mt-3" onClick={() => deleteCard("n_card_num_3")}>Delete card 4</button></Form.Label>
    )
  }

  function deleteCard(card_id) {
    document.getElementById(card_id).defaultValue = 0;
  }
  const handleAddcardsubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { n_f_name, n_l_name, n_card_num, n_card_num_1, n_card_num_2, n_card_num_3 } = e.target.elements;
    let n_details = {
      n_f_name: n_f_name.value,
      n_l_name: n_l_name.value,
      n_card_num: n_card_num.value,
      n_card_num_1: n_card_num_1.value,
      n_card_num_2: n_card_num_2.value,
      n_card_num_3: n_card_num_3.value,
    };

    if (n_card_num.value === '') { n_details.n_card_num = 0 }
    if (n_card_num_1.value === '') { n_details.n_card_num_1 = 0 }
    if (n_card_num_2.value === '') { n_details.n_card_num_2 = 0 }
    if (n_card_num_3.value === '') { n_details.n_card_num_3 = 0 }

    let postdata = '{"fields":{"f_name":{"stringValue":"' + n_details.n_f_name + '"},"l_name":{"stringValue":"' + n_details.n_l_name + '"},"email":{"stringValue":"' + currentUser.email + '"},"box_num":{"integerValue":"' + testobjj.box_num + '"},"card_num":{"integerValue":"' + n_details.n_card_num + '"},"card_num_1":{"integerValue":"' + n_details.n_card_num_1 + '"},"card_num_2":{"integerValue":"' + n_details.n_card_num_2 + '"},"card_num_3":{"integerValue":"' + n_details.n_card_num_3 + '"}}}'
    let response = await fetch(testobjj.post_base_url + testobjj.user_adress, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'PATCH',
      body: postdata
    });

    setStatus("Submit");
    if (response.status === 200)
      alert("Successfully updated");
    else
      alert("Submition status " + response.status);
  }

  return (
    <>
      <Card style={{backgroundColor: "transparent", border: "0"}}>
        <Card.Body>
        <h2 className="text-center mb-4" style={{color: "gray"}}>Credentials</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                ref={emailRef}
                required
                defaultValue={currentUser.email}
              />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                ref={passwordRef}
                placeholder="Leave blank to keep the same"
              />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control
                type="password"
                ref={passwordConfirmRef}
                placeholder="Leave blank to keep the same"
              />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Update
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <Card style={{backgroundColor: "transparent", border: "0"}}>
        <Card.Body>
        <h2 className="text-center mb-4" style={{color: "gray"}}>Profile Information</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <div className="w-100 mt-2 ">


            <Form id="myform" onSubmit={handleAddcardsubmit}>
              <Form.Group id="fname">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  type="text"
                  id="n_f_name"
                  defaultValue={testobjj.fn}
                />
              </Form.Group>
              <Form.Group id="lname">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  id="n_l_name"
                  defaultValue={testobjj.ln}
                />
              </Form.Group>
              <Form.Group id="card1">
                {card_1_delete_button}
                <Form.Control
                  type="hidden"
                  id="n_card_num"
                  defaultValue={testobjj.card_num}
                />
              </Form.Group>
              <Form.Group id="card2">
                {card_2_delete_button}
                <Form.Control
                  type="hidden"
                  id="n_card_num_1"
                  defaultValue={testobjj.card_num_1}
                />
              </Form.Group>
              <Form.Group id="card3">
                {card_3_delete_button}
                <Form.Control
                  type="hidden"
                  id="n_card_num_2"
                  defaultValue={testobjj.card_num_2}
                />
              </Form.Group>

              <Form.Group id="card4">
                {card_4_delete_button}

                <Form.Control
                  type="hidden"
                  id="n_card_num_3"
                  defaultValue={testobjj.card_num_3}
                />
              </Form.Group>

              <Button className="w-100" type="submit">
                {status}
              </Button>
            </Form>
          </div>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Link to="/">Cancel</Link>
      </div>
    </>
  )
}