//import React from "react"
import React, { useState } from "react"
import { Alert } from "react-bootstrap"
import { Link } from "react-router-dom"

export default function Display_Info() {
    const mqtt = require('mqtt')
    const [msg, setMsg] = useState("")
    const [sensors, setsensors] = useState("")

    const options = {
      clean: true,
      clientId: 'webbutton',
      useSSL: true ,
      username: 'ibrahim',
      password: 'Aa789789'
    }
    //const client  = mqtt.connect('wss://5247d351f17c44b981643861ca6beade.s2.eu.hivemq.cloud:8884/mqtt', options)
    // client.on('connect', function (connack) {
    //   console.log('Connected')
    //   // client.publish('testTopic', 'Triggered From web')
    // })

    // client.publish('testTopic', '13', { qos: 0, retain: false }, function (error) {
    //   if (error) {
    //     console.log(error)
    //   } else {
    //     console.log('Published')
    //   }
    // })
    // client.on('message', function (topic, message, packet) {
    //   // message is Buffer
    //   console.log(message)
    //   console.log(packet)
    //   client.end()
    // })
    // client.on('message', (topic, message) => {
    //   // const mypayload = { topic, message: message.toString() };
    //   setsensors(message.toString())
    //   console.log(message.toString());
    // });
    async function handlePump() {
      const client  = mqtt.connect('wss://5247d351f17c44b981643861ca6beade.s2.eu.hivemq.cloud:8884/mqtt', options)
      
      client.publish('testTopic', '14', { qos: 0, retain: false }, function (error) {
        if (error) {
          console.log(error)
        } else {
          console.log('Published')
        }
      })
      setMsg("Water Pump has been  succeffully triggered!")
      // alert("Water Pump has been triggered!")
    }
    async function handlereading() {
    const client  = mqtt.connect('wss://5247d351f17c44b981643861ca6beade.s2.eu.hivemq.cloud:8884/mqtt', options)
    client.subscribe('plantTopic', function (err) {
      if (!err) {
        client.publish('plantTopic', 'Connecting to the sensors ...')
        console.log('published to plantTopic')
      }
    })
    client.publish('testTopic', '15', { qos: 0, retain: false }, function (error) {
      if (error) {
        console.log(error)
      } else {
        console.log('Published')
      }
    })
      client.on('message', (topic, message) => {
        // const mypayload = { topic, message: message.toString() };
        setsensors(message.toString())
        console.log(message.toString());
      });
    }

  return (
    <>
      {/* {error && <Alert variant={variant}>{error}</Alert>} */}

      
      <Alert.Heading>{msg && <Alert variant='success'>{msg}</Alert>}</Alert.Heading>
      {/* {sensors && <Alert variant='info'>{sensors}</Alert>} */}
      {sensors}
      <button className="btn btn-primary w-100 mt-3" onClick={handlereading}>
      <img src={"reload.png"} alt={"reopen"} style={{ width: "30px", marginRight: "10px"  }} ></img> Get Sensor Values</button>
      <button className="btn btn-primary w-100 mt-3" onClick={handlePump}><img src={"water.png"} alt={"water"} style={{ width: "30px", marginRight: "10px"  }} ></img>  Trigger Water Pump</button>
      <Link to="/open-front-door" className="btn btn-primary w-100 mt-3">
        <img src={"door.png"} alt={"door"} style={{ width: "30px", marginRight: "10px"  }} ></img> Open Front Door 
      </Link>  
      <Link to="/" className="btn btn-secondary w-100 mt-3">
        Back to Dashboard
      </Link> 
      <br/>
      <br/>
    </>
  )
}
