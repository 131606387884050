import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { Link } from "react-router-dom"
const fetch = require('sync-fetch');

// eslint-disable-next-line
var userNewObj = new Object();
export function getUserDetails(emailRef) {

    if (emailRef !== undefined) {
        for (var userNewObjKey in userNewObj) {
            if (userNewObj.hasOwnProperty(userNewObjKey)) {
                delete userNewObj[userNewObjKey];
            }
        }
        const post_base_url = "https://firestore.googleapis.com/v1/"
        const url = 'https://firestore.googleapis.com/v1/projects/esp32-smartmailbox/databases/(default)/documents/';
        const index_url = 'https://firestore.googleapis.com/v1/projects/esp32-smartmailbox/databases/(default)/documents/index';

        let getUserData = fetch(index_url).json()
        var addr = undefined;
        if (getUserData !== undefined) {
            for (let j = 0; j < getUserData.documents.length; j++) {
                if (getUserData.documents[j].fields.email !== undefined) {
                    if (getUserData.documents[j].fields.email.stringValue.toString().trim() === emailRef.toString().trim()) {
                        addr = j;
                    }
                }
            }
        }
        if (addr !== undefined) {
            if (getUserData.documents[addr].fields.address !== undefined) { 
                var u_a = getUserData.documents[addr].fields.address.stringValue
            }
            var testob = fetch(url + u_a).json()
            var doc = undefined;
            for (let i = 0; i < testob.documents.length; i++) {
                if (testob.documents[i].fields.email !== undefined) {
                    if (testob.documents[i].fields.email.stringValue.toString().trim() === emailRef.toString().trim()) {
                        doc = i;
                    }
                }
            }

            if (doc !== undefined) {
                var cnn_o = 'card_num';
                for (let card_ind = 0; card_ind < 12; card_ind++) {
                    if (testob.documents[doc].fields[cnn_o] !== undefined && testob.documents[doc].fields[cnn_o].integerValue !== '0') {
                        userNewObj[cnn_o] = testob.documents[doc].fields[cnn_o].integerValue;
                    }
                    cnn_o = 'card_num_' + card_ind;
                }
                if (testob.documents[doc].fields.box_num !== undefined) { var box_num = testob.documents[doc].fields.box_num.integerValue }
                if (testob.documents[doc].fields.f_name !== undefined) { var fn = testob.documents[doc].fields.f_name.stringValue }
                if (testob.documents[doc].fields.l_name !== undefined) { var ln = testob.documents[doc].fields.l_name.stringValue }
                if (testob.documents[doc].fields.email !== undefined) { var user_email = testob.documents[doc].fields.email.stringValue }
                var is_admin = undefined;
                if (testob.documents[doc].fields.admin !== undefined) { is_admin = testob.documents[doc].fields.admin.booleanValue } else { is_admin = "false" }
                if (testob.documents[doc].name !== undefined) { var user_adress = testob.documents[doc].name }
                if (testob.documents[doc].fields.last_access !== undefined) { var last_access = testob.documents[doc].fields.last_access.stringValue }
            }
            userNewObj.doc = doc
            userNewObj.user_adress = user_adress
            userNewObj.post_base_url = post_base_url
            userNewObj.ln = ln
            userNewObj.fn = fn
            userNewObj.box_num = box_num
            userNewObj.last_access = last_access
            userNewObj.user_email = user_email
            userNewObj.is_admin = is_admin
            userNewObj.address = u_a
            userNewObj.index = getUserData.documents[addr].name
        }
    }
    return userNewObj
}

export default function UpdateUserProfil() {
    const emailRef = useRef()
    const [status, setStatus] = useState("Search");
    const [error, setError] = useState("");
    const [hiding, sethiding] = useState(true)
    const [button_class_change, setButtonStatus] = useState("btn btn-primary w-100 mt-3");

    async function handleFindUser(e) {
        e.preventDefault()

        try {
            setError("");
            setStatus("Searching...");
            var userNewObj = getUserDetails(emailRef.current.value);
            if (userNewObj.user_email === undefined) {
                setError("User not found")
            }
            if (userNewObj.is_admin !== undefined && userNewObj.is_admin !== true) {
                sethiding(false)
                setButtonStatus("btn btn-secondary w-100 mt-3");
            }
            else if (userNewObj.is_admin) {
                setError("Updating admin accounts is unauthorized")
            }
            setStatus("New Search");
        } catch {
            setStatus("New Search");
            setError("Search failed")
        }
    }

    return (
        <>
      <Card style={{backgroundColor: "transparent", border: "0"}}>
                <Card.Body>
                <h2 className="text-center mb-4" style={{color: "gray"}}>Find User</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <div className="w-100 mt-2 ">
                        <Form id="myform" onSubmit={handleFindUser}>
                            <Form.Group id="email_address">
                                <Form.Label>Email *</Form.Label>
                                <Form.Control
                                    type="email" ref={emailRef} required
                                />
                            </Form.Group>
                            <Link hidden={hiding} to="/update-user" className="btn btn-primary w-100 mt-3">
                                Update user account</Link>
                            <Button className={button_class_change} type="submit">
                                {status}
                            </Button>
                        </Form>
                    </div>
                    <div className="w-100 text-center mt-2">
                        <Link to="/">Cancel</Link>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}
export const user_infos11 = userNewObj
