import React from "react"
import Signup from "./Signup"
import { Container } from "react-bootstrap"
import { AuthProvider } from "../contexts/AuthContext"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Dashboard from "./Dashboard"
import Login from "./Login"
import PrivateRoute from "./PrivateRoute"
import ForgotPassword from "./ForgotPassword"
import UpdateProfile from "./UpdateProfile"
import UpdateAdminProfile from "./UpdateAdminProfile"
import UpdateUser from "./UpdateUser"
import AddUser from "./AddUser"
import AddAdmin from "./AddAdmin"
import FindUser from "./FindUser"
import OpenFrontdoor from "./OpenFrontdoor"
import IoTDashboard from "./IoTDashboard"


function App() {
  return (
  <>
      <Container
        className=" d-flex card_bg align-items-center justify-content-center"
        style={{ marginTop: "86px" }}
      >
        <div className="w-100" style={{ maxWidth: "1000px",  }}>
          <Router>
            <AuthProvider>
              <Switch>
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute path="/update-profile" component={UpdateProfile} />
                <PrivateRoute path="/admin_tools" component={AddUser} />
                <PrivateRoute path="/add-admin" component={AddAdmin} />
                <PrivateRoute path="/update-admin" component={UpdateAdminProfile} />
                <PrivateRoute path="/update-user" component={UpdateUser} />
                <PrivateRoute path="/find-user" component={FindUser} />
                <PrivateRoute path="/open-front-door" component={OpenFrontdoor} />
                <PrivateRoute path="/IoTDashboard" component={IoTDashboard} />
                <Route path="/signup" component={Signup} />
                <Route path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />
              </Switch>
            </AuthProvider>
          </Router>
        </div>
      </Container>

  </>
  )
}
export default App
