import React, { useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { Link } from "react-router-dom"
import { user_infos11 } from "./FindUser"

export default function UpdateUserProfil() {
    const [error, setError] = useState("");
    const [status, setStatus] = useState("Submit");

    var testobjj = user_infos11
    function deleteCard(card_id) {
        document.getElementById(card_id).defaultValue = 0;
    }
    const handleAddcardsubmit = async (e) => {
        e.preventDefault();
        setError("");
        setStatus("Sending...");
        const { n_f_name, n_l_name, n_email, n_address, n_box_num, n_card_num, n_card_num_1, n_card_num_2, n_card_num_3 } = e.target.elements;
        let n_details = {
            n_f_name: n_f_name.value,
            n_l_name: n_l_name.value,
            n_email: n_email.value,
            n_address: n_address.value,
            n_box_num: n_box_num.value,
            n_card_num: n_card_num.value,
            n_card_num_1: n_card_num_1.value,
            n_card_num_2: n_card_num_2.value,
            n_card_num_3: n_card_num_3.value,
        };

        if (n_card_num.value === '') { n_details.n_card_num = 0 }
        if (n_card_num_1.value === '') { n_details.n_card_num_1 = 0 }
        if (n_card_num_2.value === '') { n_details.n_card_num_2 = 0 }
        if (n_card_num_3.value === '') { n_details.n_card_num_3 = 0 }

        if (testobjj.address !== n_details.n_address || testobjj.user_email !== n_details.n_email) {

            let postaddr = '{"fields":{"address":{"stringValue":"' + n_details.n_address + '"},"email":{"stringValue":"' + n_details.n_email + '"}}}'
            let addr_response = await fetch(testobjj.post_base_url + testobjj.index, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'PATCH',
                body: postaddr
            });

            if (addr_response.status === 200) {

                setError("Successfully added Address to user index");


                let postdata = '{"fields":{"f_name":{"stringValue":"' + n_details.n_f_name + '"},"l_name":{"stringValue":"' + n_details.n_l_name + '"},"email":{"stringValue":"' + n_details.n_email + '"},"box_num":{"integerValue":"' + n_details.n_box_num + '"},"card_num":{"integerValue":"' + n_details.n_card_num + '"},"card_num_1":{"integerValue":"' + n_details.n_card_num_1 + '"},"card_num_2":{"integerValue":"' + n_details.n_card_num_2 + '"},"card_num_3":{"integerValue":"' + n_details.n_card_num_3 + '"}}}'
                let response = await fetch("https://firestore.googleapis.com/v1/projects/esp32-smartmailbox/databases/(default)/documents/" + n_details.n_address + "?documentId=" + n_details.n_f_name + '.' + n_details.n_l_name, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: postdata
                });

                setStatus("Submit");
                if (response.status === 200) {
                    setError("Successfully added to the new adress");
                    let del_old_data = await fetch(testobjj.post_base_url + testobjj.user_adress, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        method: 'DELETE',
                    });
                    if (del_old_data.status === 200)
                        setError("Successfully updated");

                }
                else
                    setError("address Submition status " + addr_response.status);
            }
            else
                setError("Submition status to updating address in Database index " + addr_response.status);

        }
        else {
            let postdata = '{"fields":{"f_name":{"stringValue":"' + n_details.n_f_name + '"},"l_name":{"stringValue":"' + n_details.n_l_name + '"},"email":{"stringValue":"' + n_details.n_email + '"},"box_num":{"integerValue":"' + n_details.n_box_num + '"},"card_num":{"integerValue":"' + n_details.n_card_num + '"},"card_num_1":{"integerValue":"' + n_details.n_card_num_1 + '"},"card_num_2":{"integerValue":"' + n_details.n_card_num_2 + '"},"card_num_3":{"integerValue":"' + n_details.n_card_num_3 + '"}}}'
            let response = await fetch(testobjj.post_base_url + testobjj.user_adress, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'PATCH',
                body: postdata
            });

            setStatus("Submit");
            if (response.status === 200)
                setError("Successfully updated user Info");
            else
                setError("Submition status to user Info " + response.status);
        }

    }

    return (
        <>
      <Card style={{backgroundColor: "transparent", border: "0"}}>
                <Card.Body>
                <h2 className="text-center mb-4" style={{color: "gray"}}>Profile Information</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <div className="w-100 mt-2 ">


                        <Form id="myform" onSubmit={handleAddcardsubmit}>
                            <Form.Group id="fname">
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="n_f_name"
                                    defaultValue={testobjj.fn}
                                />
                            </Form.Group>
                            <Form.Group id="lname">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="n_l_name"
                                    defaultValue={testobjj.ln}
                                />
                            </Form.Group>

                            <Form.Group id="n_email">
                                <Form.Label>Email *</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    id="n_email"
                                    defaultValue={testobjj.user_email}
                                />
                            </Form.Group>

                            <Form.Group id="n_address">
                                <Form.Label>Address *</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    id="n_address"
                                    defaultValue={testobjj.address}
                                />
                            </Form.Group>

                            <Form.Group id="box_num">
                                <Form.Label>Box number</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="n_box_num"
                                    defaultValue={testobjj.box_num}
                                />
                            </Form.Group>

                            <Form.Group id="card1">
                                <Form.Label>Card 1</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="n_card_num"
                                    defaultValue={testobjj.card_num}
                                />
                                <Form.Label><button className="btn btn-delete w-100 mt-3" onClick={() => deleteCard("n_card_num")}>Delete card 1</button></Form.Label>
                            </Form.Group>

                            <Form.Group id="card2">
                                <Form.Label>Card 2</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="n_card_num_1"
                                    defaultValue={testobjj.card_num_1}
                                />
                                <Form.Label><button className="btn btn-delete w-100 mt-3" onClick={() => deleteCard("n_card_num_1")}>Delete card 2</button></Form.Label>
                            </Form.Group>

                            <Form.Group id="card3">
                                <Form.Label>Card 3</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="n_card_num_2"
                                    defaultValue={testobjj.card_num_2}
                                />
                            </Form.Group>
                            <Form.Label><button className="btn btn-delete w-100 mt-3" onClick={() => deleteCard("n_card_num_2")}>Delete card 3</button></Form.Label>

                            <Form.Group id="card4">
                                <Form.Label>Card 4</Form.Label>

                                <Form.Control
                                    type="number"
                                    id="n_card_num_3"
                                    defaultValue={testobjj.card_num_3}
                                />
                                <Form.Label><button className="btn btn-delete w-100 mt-3" onClick={() => deleteCard("n_card_num_3")}>Delete card 4</button></Form.Label>

                            </Form.Group>


                            <Button className="w-100" type="submit">
                                {status}
                            </Button>
                        </Form>
                    </div>
                    <div className="w-100 text-center mt-2">
                <Link to="/">Cancel</Link>
            </div>
                </Card.Body>
            </Card>
            
        </>
    )
}
