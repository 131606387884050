import React, { useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { Link } from "react-router-dom"

export default function AddUser() {
    const [error] = useState("")
    const [status, setStatus] = useState("Submit");
    let post_base_url = "https://firestore.googleapis.com/v1/projects/esp32-smartmailbox/databases/(default)/documents/"
    const handleAddUserSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");
        const { n_f_name, n_l_name, n_email, n_box_num, n_card_num, n_address, n_card_num_1, n_card_num_2, n_card_num_3 } = e.target.elements;
        let n_details = {
            n_f_name: n_f_name.value.toLowerCase(),
            n_l_name: n_l_name.value.toLowerCase(),
            n_email: n_email.value.toLowerCase(),
            n_box_num: n_box_num.value,
            n_card_num: n_card_num.value,
            n_card_num_1: n_card_num_1.value,
            n_card_num_2: n_card_num_2.value,
            n_card_num_3: n_card_num_3.value,
            n_address: n_address.value.toLowerCase()
        };

        if (n_card_num.value === '') { n_details.n_card_num = 0 }
        if (n_card_num_1.value === '') { n_details.n_card_num_1 = 0 }
        if (n_card_num_2.value === '') { n_details.n_card_num_2 = 0 }
        if (n_card_num_3.value === '') { n_details.n_card_num_3 = 0 }
        let addToIndex = '{"fields":{"address":{"stringValue":"' + n_details.n_address + '"},"email":{"stringValue":"' + n_details.n_email + '"}}}'
        let indexResponse = await fetch("https://firestore.googleapis.com/v1/projects/esp32-smartmailbox/databases/(default)/documents/index?documentId=" + n_details.n_f_name + '.' + n_details.n_l_name, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: addToIndex
        });
        let postdata = '{"fields":{"f_name":{"stringValue":"' + n_details.n_f_name + '"},"l_name":{"stringValue":"' + n_details.n_l_name + '"},"email":{"stringValue":"' + n_details.n_email + '"},"box_num":{"integerValue":"' + n_details.n_box_num + '"},"card_num":{"integerValue":"' + n_details.n_card_num + '"},"card_num_1":{"integerValue":"' + n_details.n_card_num_1 + '"},"card_num_2":{"integerValue":"' + n_details.n_card_num_2 + '"},"card_num_3":{"integerValue":"' + n_details.n_card_num_3 + '"}}}'
        let response = await fetch(post_base_url + n_details.n_address + "?documentId=" + n_details.n_f_name + '.' + n_details.n_l_name, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: postdata
        });
        setStatus("Submit");
        if (response.status === 200 && indexResponse.status === 200)
            alert("Successfully updated");
        else
            alert("Submition status " + response.status + " adding to index status " + indexResponse.status);
    }


    return (
        <>
      <Card style={{backgroundColor: "transparent", border: "0"}}>
                <Card.Body>
                    <h2 className="text-center mb-4" style={{color: "gray"}}>Add new User</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <div className="w-100 mt-2 ">


                        <Form id="myform" onSubmit={handleAddUserSubmit}>
                            <Form.Group id="fname">
                                <Form.Label>First name *</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    id="n_f_name"
                                    placeholder="First name (required!)"
                                />
                            </Form.Group>
                            <Form.Group id="lname">
                                <Form.Label>Last Name *</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    id="n_l_name"
                                    placeholder="Last name (required!)"
                                />
                            </Form.Group>

                            <Form.Group id="n_email">
                                <Form.Label>Email *</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    id="n_email"
                                    placeholder="Email for user login (required!)"

                                />
                            </Form.Group>

                            <Form.Group id="n_address">
                                <Form.Label>Address *</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    id="n_address"
                                    placeholder="Adress for user login example: teststrasse10 (required!)"

                                />
                            </Form.Group>

                            <Form.Group id="box">
                                <Form.Label>Box Number *</Form.Label>
                                <Form.Control
                                    type="number"
                                    required
                                    id="n_box_num"
                                    placeholder="box number (required!)"
                                />
                            </Form.Group>

                            <Form.Group id="card1">
                                <Form.Label>Card 1 *</Form.Label>
                                <Form.Control
                                    type="number"
                                    required
                                    id="n_card_num"
                                    placeholder="First card number (required!)"
                                />
                            </Form.Group>


                            <Form.Group id="card2">
                                <Form.Label>Card 2:</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="n_card_num_1"
                                />
                            </Form.Group>


                            <Form.Group id="card3">
                                <Form.Label>Card 3:</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="n_card_num_2"
                                />
                            </Form.Group>

                            <Form.Group id="card4">
                                <Form.Label>Card 4:</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="n_card_num_3"
                                />
                            </Form.Group>

                            <Button className="w-100" type="submit">
                                {status}
                            </Button>
                        </Form>
                    </div>
                    <div className="w-100 text-center mt-2">
                        <Link to="/">Cancel</Link>
                    </div>
                </Card.Body>
            </Card>
        </>
    )

}