import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      history.push("/")
    } catch {
      setError("Failed to log in")
    }

    setLoading(false)
  }

  return (
    <>
      {/* <Card style={{backgroundColor: "transparent", border: "0"}}>
      <img src={"welcome_gif.svg"} alt={"hi"} style={{  display: "block", marginLeft: "auto", marginRight: "auto", width:"50%"}}></img>
      </Card> */}
      <Card style={{backgroundColor: "transparent", border: "0"}}>
        <Card.Body>
          <h2 className="text-center mb-4" style={{color: "gray"}}>Log In</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Log In
            </Button>
          </Form>
          <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2" style={{color: "red"}}>
        Need an account? <Link to="/signup">Sign Up</Link>
      </div>
      <div className="w-100 text-center mt-2">
        {/* <br></br>
        <iframe title="info" width="auto" height="auto" src="https://www.youtube.com/embed/AAfwDZJ4OPeEE?rel=0&iv_load_policy=3&amp;vq=hd1080" fmt="35" frameBorder="0" allowFullScreen={true}>
        </iframe> */}
      </div>
    </>
  )
}
